.teacher-note-empty-state {
  display: block;
  text-align: center;
}

.teacher-note-container {
  display: flex;
  flex-flow: column;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  margin-bottom: 15px;
}

.teacher-note-container:last-of-type {
  margin-bottom: 0;
}

.teacher-note-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  font-size: 0.8em;
}

.teacher-note-header-curriculum {
  display: block;
  font-weight: 700;
}

.teacher-note-header-date {
  display: block;
  margin-top: -5px;
}

.teacher-note-content {
  padding: 10px;
  min-height: 75px;
  white-space: pre-wrap;
}
