.dialog-column-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 24px;
}

.dialog-column-2 > .card-content {
  padding: 0 12px;
}

#create-teacher-dialog-per-period-inputs {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  gap: 12px;
}
