.planning-study-year-actions {
  padding: 6px 12px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  background-color: #003399;
  color: white;
  border-radius: 4px;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
}

.planning-study-year-actions > div {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.planning-study-year-container {
  width: 100%;
  min-height: 300px;
  background-color: white;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  margin-top: 12px;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: 1fr 1px 250px;
  grid-template-rows: auto;
  grid-template-areas: "study_year_planning divider notes";
  position: relative;
}

.planning-study-year-divider {
  grid-area: divider;
  width: 1px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.12);
}

.planning-study-year-cells-container {
  grid-area: study_year_planning;
  height: min-content;
  overflow-y: auto;
  padding-bottom: 2px;
  height: 100%;
}

.planning-study-year-notes-container {
  grid-area: notes;
  height: 100%;
  /* border-left: 1px solid rgba(0, 0, 0, 0.12); */
  max-height: min-content;
  padding: 0 12px;
  overflow-y: auto;
}

.planning-note {
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  margin-bottom: 12px;
  cursor: pointer;
}

.planning-note-header {
  display: grid;
  grid-template-columns: 1fr 32px;
  grid-template-rows: repeat(2, 16px);
  grid-template-areas:
    "class_and_course remove"
    "teachers remove";
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 12px;
}

.planning-note-class-and-course,
.planning-note-teachers {
  font-size: 0.9em;
}

.planning-note-class-and-course {
  grid-area: class_and_course;
  font-weight: bold;
}

.planning-note-teachers {
  grid-area: teachers;
}

.planning-note-remove {
  grid-area: remove;
}

.planning-note-text {
  padding: 12px;
  white-space: pre-wrap;
  transition: 0.2s ease-in-out all;
}

.planning-note-text:hover {
  transition: 0.2s ease-in-out all;
  background-color: rgba(0, 0, 0, 0.05);
}

.planning-study-year-loading-overlay {
  grid-area: study_year_planning;
  background-color: rgba(0, 0, 0, 0.05);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 1;
  transition: 0.1s ease-in-out all;
  display: flex;
  justify-content: center;
  align-items: center;
}

.planning-study-year-loading-overlay.visible {
  z-index: 9;
  opacity: 1;
  transition: 0.1s ease-in-out all;
}

.planning-study-year-loading-overlay.invisible {
  z-index: -1;
  opacity: 0;
  transition: 0.1s ease-in-out all;
}

.planning-study-year-table-container {
  width: auto;
  border-collapse: collapse;
  border-spacing: 0;
  margin: -1px 0 -1px -1px;
  height: min-content;
}

.planning-study-year-table-container th,
.planning-study-year-table-container td {
  height: 50px;
  font-size: 14px;
  font-weight: 500;
  padding: 0;
  border: 1px solid rgb(197, 197, 197);
  text-align: center;
}
