.dialog-actions-stepper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.transfer-list-container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 70px 1fr;
  grid-template-rows: 300px;
  gap: 20px;
}

.transfer-list-custom-list-container {
  width: 100%;
  height: inherit;
}

.transfer-list-custom-list {
  width: 100%;
  height: calc(100% - 30px);
  overflow: auto;
}

.transfer-list-custom-list-title {
  display: block;
  text-align: center;
  height: 30px;
  font-weight: 700;
}

.transfer-list-buttons {
  display: flex;
  gap: 10px;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}
