.export-dialog-container {
  width: 100%;
  height: calc(100vh - 62px);
  display: flex;
  flex-flow: row;
}

.export-dialog-fullscreen-sidebar-container {
  width: 400px;
  height: 100%;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  padding: 0 24px;
}

.export-dialog-fullscreen-content-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
}

.export-dialog-fullscreen-content-header {
  width: 100%;
  height: 60px;
  padding: 0 24px;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-end;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.export-dialog-fullscreen-content {
  height: 100%;
  border: none;
}

.export-dialog-fullscreen-loading-container,
.export-dialog-fullscreen-error-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.export-planning-container {
  display: flex;
  flex-flow: column;
}

.export-planning-image-header-container {
  padding: 20px;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.export-planning-image-header-side {
  display: flex;
  flex-flow: row;
}

.export-planning-image-header-side.left {
}

.export-planning-image-header-side.right {
  text-align: right;
}

.export-planning-image-header-title {
  font-weight: bold;
  display: block;
}

.export-planning-image-header-subtitle {
  display: block;
}

#export-planning-image-header-logo {
  width: 42px;
  height: 42px;
  margin-left: 12px;
}

.export-planning-image-divider {
  height: 1px;
  width: calc(100% - 40px);
  background-color: black;
  margin: 0 20px 20px 20px;
}

.export-planning-image {
  padding: 0 20px 20px 20px;
  width: 100%;
  height: auto;
}
