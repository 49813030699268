#curricula-formula {
  display: flex;
}

#curricula-inputs {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  gap: 24px;
}

.formula-variable {
  margin: 0 5px;
}

.curriculum-mot-table {
  max-height: 500px;
  white-space: nowrap;
  overflow: scroll;
}
