#content-container {
  width: 100%;
  height: 100%;
}

#content-container.sidebar {
  display: grid;
  grid-template-columns: 250px 1fr;
  grid-template-rows: 1fr;
  grid-template-areas:
    "sidebar content"
    "sidebar content";
}

#content-container.topbar {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 70px 1fr;
  grid-template-areas:
    "topbar topbar"
    "content content";
}

#content-container.sidebar.topbar {
  display: grid;
  grid-template-columns: 250px 1fr;
  grid-template-rows: 70px 1fr;
  grid-template-areas:
    "sidebar topbar"
    "sidebar content";
}

#sidebar {
  grid-area: sidebar;
  width: 250px;
  height: 100%;
  background-color: white;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}

#topbar {
  grid-area: topbar;
  width: 100%;
  height: 70px;
  background-color: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 0 24px;
}

#content {
  width: calc(100% - 48px);
  height: calc(100% - 48px);
  grid-area: content;
  margin: 24px;
}

/* Sidebar */

/* Topbar */
#content-container.topbar > #topbar {
}

#content-container.sidebar > #topbar {
}

#content-container.topbar > #topbar.normal {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  align-items: center;
}

#topbar-normal-left {
  display: flex;
  align-items: center;
}

#topbar-normal-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

#topbar-normal-right > * {
  margin-left: 16px;
  margin-bottom: 0;
}

#topbar-current-curriculum {
  margin-right: 24px;
}

#topbar-current-curriculum-and-kind {
  margin-right: 24px;
}

#topbar-searchbar {
  background-color: #0000000f;
  border: none;
  border-radius: 4px;
  width: 100%;
  height: 37.625px;
  max-width: 300px;
  padding: 0 16px;
}

#topbar-create-dialog-button {
}

#content-container.topbar > #topbar.planning {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: auto;
  align-items: center;
}

#topbar-planning-left {
}

#topbar-planning-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

#topbar-planning-right > * {
  margin-left: 16px;
  margin-bottom: 0;
}
