.details-topbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#details-specific {
  font-weight: 700;
}

.details-topbar-red {
  color: #cc0033;
}

#teacher-personalia {
  grid-area: personalia;
}

#teacher-placeholder {
  margin: -24px 0 -24px 0 !important;
}

#teacher-personalia-actions {
  display: flex;
  flex-flow: column;
  gap: 10px;
}

#teacher-tabs {
  grid-area: tabs;
}

#teacher-notes {
  grid-area: notes;
}

#tab-aanstelling-taaktoedeling-container,
#tab-onderwijstaken-taaktoedeling-container,
#tab-onderwijstaken-supervision-summary-container {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  overflow: auto;
}

#tab-onderwijstaken-taaktoedeling-container {
  overflow: auto;
}

#tab-onderwijstaken-taaktoedeling-container > table {
  table-layout: fixed;
}

#tab-onderwijstaken-taaktoedeling-container th,
#tab-onderwijstaken-taaktoedeling-container td {
  padding: 10px !important;
}

.tab-aanstelling-taaktoedeling-not-in-calculation {
}

#tab-onderwijstaken-column-name {
  width: 400px;
}

.onderwijstaken-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 34px;
}

@media only screen and (max-width: 1550px) {
  #teacher-aanstelling-top-fields-container {
    display: grid;
    grid-template-columns: repeat(2, min-content);
    grid-template-rows: repeat(2, min-content);
    gap: 12px;
  }
}
