#navigation-bar {
  grid-area: navigation-bar;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 90px 1fr 70px;
  grid-template-areas:
    "logo-and-system"
    "links"
    "log-out";
  width: 100%;
  height: 100%;
  max-width: 250px;
  background-color: white;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}

#navigation-bar--logo-and-system {
  grid-area: logo-and-system;
  display: flex;
  align-items: center;
}

#navigation-bar--logo-and-system > div {
  padding-left: 26px;
  display: flex;
  align-items: center;
}

#navigation-bar--logo {
  width: 32px;
  margin-right: 12px;
}

#navigation-bar--links {
  grid-area: links;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: repeat(5, 40px);
  gap: 10px;
}

#navigation-bar--links > a {
  color: #6a6a6a;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding-left: 26px;
  margin-right: 15px;
  transition: all 0.2s ease-in-out;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

#navigation-bar--links > a > svg {
  margin-right: 12px;
}

.navigation-bar-link-active {
  color: #cc0033 !important;
  background-color: rgb(204, 0, 51, 0.12);
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  transition: all 0.2s ease-in-out;
}

.navigation-bar-version,
.navigation-bar-commit-hash {
  display: block;
  text-align: center;
  color: #6a6a6a;
  font-size: 0.9em;
}

#navigation-bar--log-out {
  grid-area: log-out;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  display: flex;
  justify-content: flex-start;
  color: #6a6a6a;
}

#navigation-bar--log-out > div {
  display: flex;
  align-items: center;
  padding-left: 26px;
}

#navigation-bar--log-out > div > svg {
  margin-right: 12px;
}

#app-content {
  grid-area: app-content;
}
