#auth-manager-status {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: #fff7ea !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #001e48;
}

@keyframes logged-in-kf {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(-100vh);
    display: none;
    visibility: hidden;
  }
}

#auth-manager-status.logged-in {
  animation-name: logged-in-kf;
  animation-duration: 0.5s;
  animation-delay: 0.5s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

#auth-manager-status > #auth-manager-logo {
  max-width: 100px;
  margin-top: -200px;
  margin-bottom: 50px;
}

#auth-manager-status > h1 {
  margin-bottom: 20px;
}

#auth-manager-status > h2 {
  margin-bottom: 20px;
}
