.cell-overlay-container {
  display: flex;
  flex-flow: row;
}

.cell-overlay-section {
  width: 100%;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    "header"
    "content"
    "footer";
}

.cell-overlay-section.class {
  width: 300px;
}

.cell-overlay-section.course-details {
  width: 460px;
}

.cell-overlay-section.course-autocomplete {
  width: 300px;
}

.cell-overlay-section.teacher {
  width: 291px;
}

.cell-overlay-section.teacher-side {
  width: 291px;
}

.cell-overlay-section-header {
  grid-area: header;
  margin: 16px 12px 24px 12px;
}

.cell-overlay-section-instruction {
  display: inline-block;
  font-size: 12px;
  color: #808080;
  width: 100%;
  text-align: center;
}

.cell-overlay-section-content {
  grid-area: content;
  margin: 0 12px;
}

.cell-overlay-section-content > .column-2 {
  display: grid;
  gap: 12px;
  grid-template-columns: 1fr 1fr;
}

.cell-overlay-section-content > * {
  margin-bottom: 24px !important;
}

.cell-overlay-section-content > *:last-child {
  margin-bottom: 0 !important;
}

.cell-overlay-section-footer {
  grid-area: footer;
  margin: 24px 12px;
}

.cell-overlay-section.class > .cell-overlay-section-content > .op {
  display: flex;
  flex-flow: row;
  gap: 12px;
}

.cell-overlay-teacher-container {
  display: flex;
  flex-flow: column;
}

.cell-overlay-teacher-header-container {
  display: flex;
  flex-flow: row;
  width: 581px;
  padding: 12px;
  align-items: center;
}

.cell-overlay-teacher-header-course {
  font-weight: bold;
  margin-right: 12px;
  display: flex;
}

.cell-overlay-teacher-header-teacher-workload {
  display: flex;
  font-weight: bold;
  color: #003399;
  margin-left: 12px;
  align-items: center;
  margin-right: auto;
}

.cell-overlay-teacher-details-container {
  display: grid;
  grid-template-columns: 1fr 1px 1fr;
  flex-flow: row;
}

.cell-overlay-teacher-assing-container {
  display: flex;
  flex-flow: column;
  padding: 0 12px;
}

.cell-overlay-teacher-assigned-item {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center;
  border-radius: 4px;
  margin-bottom: 6px;
}

.cell-overlay-teacher-assigned-personnel-code {
  justify-self: center;
}

.cell-overlay-teacher-assigned-actions {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: -16px;
}

.cell-overlay-teacher-assigned-workload {
  margin-right: 6px;
}

.cell-overlay-teacher-assigned-workload-override-input {
  width: 70px;
}

.cell-overlay-teacher-assigned-remove-button {
  margin-left: 6px !important;
  width: 23.5px;
  height: 23.5px;
}

.cell-overlay-teacher-assing-available-teachers {
  min-height: 80px;
  max-height: 275px;
  overflow: auto;
}

.cell-overlay-teacher-assing-available-teachers ul {
  padding: 0;
}

.available-teachers-list-header {
  background-color: rgba(247, 247, 247, 1) !important;
  border-radius: 4px;
  line-height: 40px !important;
}

.available-teachers-list-item {
  border-radius: 4px !important;
  margin: 6px 0 !important;
}

.available-teachers-list-item:hover {
  background-color: rgba(0, 0, 0, 0.02) !important;
}

.available-teachers-list-empty-team {
  color: rgba(0, 0, 0, 0.5);
  text-align: center !important;
}

.cell-overlay-teacher-side-container {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 205px 1px 205px;
}

.cell-overlay-teacher-label-container {
  display: flex;
  flex-flow: column;
  padding: 12px;
  overflow: auto;
  position: relative;
}

.cell-overlay-teacher-label {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
  cursor: pointer;
}

.cell-overlay-teacher-label-remove {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 12px;
}

.cell-overlay-teacher-notes-container {
  display: grid;
  grid-template-columns: 100px 12px 1fr;
  grid-template-rows: 1fr 1fr 12px 33px;
  grid-template-areas:
    "title title title"
    "input input input"
    ". . ."
    "delete delete delete";
  overflow: auto;
  padding: 12px;
}

.cell-overlay-teacher-notes-title {
  grid-area: title;
  margin: 0 !important;
}

.cell-overlay-teacher-notes-input {
  grid-area: input;
}

.cell-overlay-teacher-notes-save {
  grid-area: save;
}

.cell-overlay-teacher-notes-delete {
  grid-area: delete;
}
