.planning-study-year-lead {
  min-width: 220px;
  /* background-color: #003399 !important; */
  background-color: #f8f8f8;
  font-weight: bold !important;
  border-top-left-radius: 4px;
}

.planning-study-year-lead > div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.planning-study-year-table-class {
  text-align: center;
  background-color: #f8f8f8;
  min-width: 110px;
}

.planning-study-year-table-class > div {
  height: 50px;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto;
  grid-template-areas: "left middle right";
}

.table-class-left {
  min-width: 10px;
  grid-area: left;
  transition: 0.2s ease-in-out all;
  border-right: 1px solid rgba(1, 1, 1, 0.075);
}

.table-class-left:hover {
  background-color: #ececec;
  transition: 0.2s ease-in-out all;
  cursor: pointer;
}

.table-class-right {
  min-width: 10px;
  grid-area: right;
  transition: 0.2s ease-in-out all;
  border-left: 1px solid rgba(1, 1, 1, 0.075);
}

.table-class-right:hover {
  background-color: #ececec;
  transition: 0.2s ease-in-out all;
  cursor: pointer;
}

.table-class-name {
  font-weight: bold;
  grid-area: middle;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  transition: 0.2s ease-in-out all;
}

.table-class-name:hover {
  background-color: #ececec;
  transition: 0.2s ease-in-out all;
  cursor: pointer;
}

.cell-active {
  transition: 0.2s ease-in-out all;
  color: white !important;
  background-color: #003399 !important;
}

.cell-workmethod-active {
  transition: 0.2s ease-in-out all;
  color: white !important;
}

.planning-study-year-table-course {
  text-align: right !important;
  background-color: #f8f8f8;
  padding: 0 !important;
}

.planning-study-year-table-course > div {
  min-height: 42px;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    "top"
    "middle"
    "bottom";
}

.table-course-top {
  min-height: 10px;
  grid-area: top;
  transition: 0.2s ease-in-out all;
  border-bottom: 1px solid rgba(1, 1, 1, 0.075);
}

.table-course-top:hover {
  background-color: #ececec;
  transition: 0.2s ease-in-out all;
  cursor: pointer;
}

.table-course-name {
  grid-area: middle;
  min-height: 50px;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: 0.2s ease-in-out all;
  font-weight: bold;
}

.table-course-name:hover {
  background-color: #ececec;
  transition: 0.2s ease-in-out all;
  cursor: pointer;
}

.table-course-bottom {
  min-height: 10px;
  grid-area: bottom;
  transition: 0.2s ease-in-out all;
  border-top: 1px solid rgba(1, 1, 1, 0.075);
}

.table-course-bottom:hover {
  background-color: #ececec;
  transition: 0.2s ease-in-out all;
  cursor: pointer;
}

.planning-study-year-table-course-workmethod {
  color: #7e7e7e;
  font-size: 10px;
}

.planning-study-year-table-cell {
  background-color: white;
  transition: 0.2s ease-in-out all;
  cursor: pointer;
}

.planning-study-year-table-cell:hover {
  background-color: #f7f7f7;
  transition: 0.2s ease-in-out all;
}

.planning-study-year-table-cell > div {
  width: inherit;
  height: inherit;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.planning-study-year-table-cell > div > span {
  margin-bottom: 2px;
}

.planning-study-year-table-cell > div > span:last-child {
  margin-bottom: 0;
}

.planning-study-year-table-add {
  min-width: 50px;
  cursor: pointer;
}
