html,
body,
#root,
#app-container,
#auth-manager {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: #fafafa;
  font-family: "Open sans", "Roboto", sans-serif;
  font-size: 14px;
  line-height: 21px;
}

#app-container {
  display: grid;
  grid-template-columns: 230px 1fr;
  grid-column: auto;
  grid-template-areas: "navigation-bar app-content";
  overflow: auto;
}

#app-content {
  display: grid;
  overflow: auto;
}

h1 {
  font-size: 32px;
  font-weight: 700;
  line-height: 42px;
  margin: 2rem 0;
  padding: 0;
}

h2 {
  font-size: 22px;
  font-weight: 700;
  line-height: 33px;
  margin: 2rem 0;
  padding: 0;
}

h2.sub {
  font-size: 20px;
  font-weight: 300;
  line-height: 30px;
  margin: 2rem 0;
  padding: 0;
}

h2.sub.red {
  color: #f7d9e1;
}

h2.sub.blue {
  color: #c5e7f1;
}

h6.intro {
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  margin: 0;
  padding: 0;
}

h6.subkop {
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  margin: 1.5rem 0;
  padding: 0;
}

.content-divided-1 {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: minmax(400px, calc(100vh - 70px - 48px));
  gap: 24px;
}

.content-divided-2 {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: minmax(400px, calc(100vh - 70px - 48px));
  gap: 24px;
}

.content-divided-teacher-details {
  display: grid;
  grid-template-columns: 1fr 2.5fr 1fr;
  grid-template-rows: 20px minmax(400px, calc(100vh - 44px - 48px));
  gap: 24px;
  grid-template-areas:
    "go_back details ."
    "personalia tabs notes";
}

.card-container {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  height: auto;
  overflow: auto;
}

.card-content {
  padding: 0 24px;
  width: 100%;
  height: inherit;
}

.card-content > div {
  margin: 24px 0;
}

.card-content > .column-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  gap: 24px;
}

.card-content > .column-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  gap: 24px;
}

.card-content > .column-4 {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(32px, 1fr));
  grid-template-rows: auto;
  gap: 24px;
}

.card-content .empty-div {
  height: 53.625px;
}

.table-container {
  max-height: calc(100% - 65px);
}

hr.MuiDivider-middle {
  margin: 0 24px !important;
}

div.dialog-error {
  background-color: rgba(244, 67, 54, 0.12);
  border: 1px solid rgba(244, 67, 54, 1);
  color: rgba(244, 67, 54);
  border-radius: 4px;
  margin: 30px 0 0 0;
  padding: 10px;
  display: flex;
  flex-flow: column;
}

div.dialog-error > span.dialog-error-type {
  font-weight: 700;
}

div.dialog-error > span.dialog-error-message {
  margin-bottom: 10px;
}

div.dialog-error > span.dialog-error-status {
  font-size: 0.9em;
}

.information-card-content-with-icon,
.information-card-content-no-icon {
  padding: 16px !important;
}

.information-card-content-with-icon {
  display: grid;
  grid-template-columns: 37px 1fr;
  grid-template-rows: 1fr;
}

.information-card-content-no-icon {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}
